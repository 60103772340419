import React from 'react'
import { data } from '../data/data.jsx'
import { useState } from 'react';
import Insta from '../assets/instagram.png'

const Card = () => {


  // Tri des données par date
  data.sort((a, b) => new Date(a.date) - new Date(b.date));

  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };


  return (
    <div className='w-full  lg:max-w-[1250px] m-auto py-6 px-4' >
      <div>
        <h1 className='uppercase text-2xl text-center sm:text-2xl font-bold lg:text-2xl'>Voici toutes les fêtes votives présentes sur le site durant l'été 2024.</h1>
        <h1 className='uppercase text-1xl text-center sm:text-1xl font-bold lg:text-xl my-4'>Au total <span className='text-red-600'>38 votives</span> ont été affichées sur le site.</h1>
        <h1 className='uppercase text-1xl text-center sm:text-1xl font-bold lg:text-xl'>C'est donc le <span className='text-red-600'>record à battre</span> pour l'été 2025 !</h1>
      </div>
      <div className={`grid ${data.length === 0 ? 'grid-cols-1 justify-items-center' : 'grid-cols-1 md:grid-cols-3'}`}>
        <React.Fragment>
          {data.map((item, index) => (
            <div key={index} className='py-3 m-2 px-2 shadow-2xl rounded-xl flex flex-col justify-between items-center hover:cursor-pointer'>
              <div className='flex w-full flex-col items-center md:items-start lg:flex-row' onClick={() => handleClick(item.imageUrl)}>
                <img className='w-[12em] object-contain border-black border-4 lg:hover:w-[14em] duration-300' src={item.imageUrl === '' ? "/images/affiches/soon.webp" : item.imageUrl} alt={item.alt} />
                <div className='lg:pl-4 sm:w-[80%] md:w-[100%] flex flex-col justify-between h-full'>
                  <div>
                    <h1 className='font-bold text-lg mt-4 underline-offset'>{item.title}</h1>
                    <p className='font-bold text-gray-600 my-2'>{item.dateShow}</p>
                    <p className='text-green-600 font-bold mb-2'>{item.price}</p>
                    <p className='text-gray-600 text-sm font-sans mb-2'>{item.description}</p>
                  </div>
                </div>
              </div>
              {item.compteInsta !== '' && (
                <a href={item.compteInsta} className='w-[326px] flex items-center justify-around py-2 mt-5 border rounded'>
                  <p className='font-bold'>Instagram de l'organisation :</p>
                  <img src={Insta} alt='' className='w-10' />
                </a>
              )}
            </div>
          ))}
          {selectedImage && (
            <div className='fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 duration-500'>
              <div className='max-w-xl mx-auto'>
                <img className='max-w-full' src={selectedImage} alt='Affiche de la fête votive' onClick={handleClose} />
                <button className='absolute top-2 right-2 text-white font-bold bg-red-500 px-4 py-2 rounded' onClick={handleClose}>
                  X
                </button>
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    </div>
  )
}

export default Card