import React from 'react'
import { useEffect, useState } from 'react'

const Navbar = () => {

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`flex justify-around w-full bg-white items-center  border-b sticky top-0 ease-out duration-300 ${isScrolled ? 'h-[8vh] bg-opacity-80' : 'h-[10vh]'}`}>
      <a href="/" className='flex items-center ml-2'>
        <img src="/images/logo.png" className='w-16 pr-2' alt="" />
      <h1 onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className=' sm:w-full text-2xl sm:text-4xl font-extrabold text-[#0d1d31] hover:cursor-pointer'>Fête Votive d'Ardèche</h1>
      </a>
      <a target='_blank' rel="noreferrer" href="https://marco-vassal.fr/" className="w-[150px] my-2 mr-4 hover:bg-white text-center text-sm min-[400px]:text-base bg-blue-500 border border-blue-500 text-white hover:text-blue-500 font-bold py-2 px-4 rounded duration-300">Qui suis-je ?</a>
    </div>
  )
}

export default Navbar