import Hero from "../Hero";
import Card from "../Card";
import React from 'react'
import Verif from "../Verif";

function Home() {
    return (
        <>
            <Hero />
            {/* <Reminder/> */}
            <Card />
            <Verif/>
        </>
    )
}

export default Home