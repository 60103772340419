import React from 'react'
import Typed from 'react-typed'
import { data } from '../data/data.jsx'
const { useState, useEffect } = React;

const Hero = () => {
    const countdownDate = new Date("2025-06-20 00:00").getTime()
    
    //Filter by date
    data.sort((a, b) => new Date(a.date) - new Date(b.date));

    
    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        setInterval(() => setNewTime(), 1000);
    }, []);

    const setNewTime = () => {
        if (countdownDate) {
            const currentTime = new Date().getTime();

            const distanceToDate = countdownDate - currentTime;

            let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
            let hours = Math.floor(
                (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
            );
            let minutes = Math.floor(
                (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
            );
            let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

            const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

            days = `${days}`;
            if (numbersToAddZeroTo.includes(hours)) {
                hours = `0${hours}`;
            } else if (numbersToAddZeroTo.includes(minutes)) {
                minutes = `0${minutes}`;
            } else if (numbersToAddZeroTo.includes(seconds)) {
                seconds = `0${seconds}`;
            }

            setState({ days: days, hours: hours, minutes, seconds });
        }
    };
    return (
        <div className='bg-hero-background w-full h-[90vh] bg-cover bg-center '>
            <div className="px-[4%] text-center w-full h-full bg-black bg-opacity-50 text-white flex justify-between flex-col items-center">
                <div className='pt-[6vh] sm:pt-[6vh]'>

                    <p className='text-xl font-extralight sm:text-2xl lg:text-4xl'>FêteVotive.fr cherche des partenaires <br /> à mettre en avant sur le site <br /> pour l'été 2025</p>
                    <a href="mailto:marco.vassal07@gmail.com" className="w-[150px] mt-6 inline-block hover:bg-white text-center bg-blue-500 border border-blue-500 text-white hover:text-blue-500 font-bold py-2 px-4 rounded duration-300">Contactez-moi</a>
                    <h1 className='uppercase text-2xl sm:text-3xl font-extrabold pt-10 lg:text-5xl'>Merci pour cet été</h1>
                    <h1 className='uppercase text-3xl sm:text-4xl font-extrabold pb-10 lg:text-6xl'>2024</h1>
                    
                        <h1 className='text-xl sm:text-xl font-bold lg:text-xl pb-4'>L'été 2024 arrive dans :</h1>
                    < div className='w-full flex font-bold justify-around items-center lg:w-[80%] lg:m-auto'>
                        <div className='w-20 border rounded flex flex-col lg:w-24'>
                            <div className='text-4xl font-bold border-b py-2 lg:text-5xl'>{state.days || '0'}</div>
                            <small className="w-full text-xs uppercase py-1 lg:text-sm">Jours</small>
                        </div>
                        <div className='w-20 border rounded flex flex-col lg:w-24'>
                            <div className='text-4xl font-bold border-b py-2 lg:text-5xl'>{state.hours || '00'}</div>
                            <small className="w-full text-xs uppercase py-1 lg:text-sm">Heures</small>
                        </div>
                        <div className='w-20 border rounded flex flex-col lg:w-24'>
                            <div className='text-4xl font-bold border-b py-2 lg:text-5xl'>{state.minutes || '00'}</div>
                            <small className="w-full text-xs uppercase py-1 lg:text-sm">Minutes</small>
                        </div>
                        <div className='w-20 border rounded flex flex-col lg:w-24'>
                            <div className='text-4xl font-bold border-b py-2 lg:text-5xl'>{state.seconds || '00'}</div>
                            <small className="w-full text-xs uppercase py-1 lg:text-sm">Secondes</small>
                        </div>
                    </div>

                    <h1 className='uppercase text-xl font-bold w-fit mx-auto mt-14 bg-black rounded-lg p-1 sm:text-2xl'>
                        Prêt à&nbsp;
                        <Typed className='text-green-400'
                            strings={[
                                'faire la fête ?',
                                'jouer à la pétanque ?',
                                "s'amuser ?"
                            ]}
                            typeSpeed={90}
                            backSpeed={70}
                            loop
                        />
                    </h1>
                </div>
                <h2 onClick={() => window.scrollTo({ top: window.pageYOffset + (window.innerHeight * 0.901), behavior: 'smooth' })} className='flex justify-center animate-bounce items-center text-xl font-bold pb-1 mb-4 sm:mb-0 sm:pb-8 sm:text-2xl hover:cursor-pointer'><img className='px-4' src="/images/Angle-double-down.svg" alt="" />Récap été 2024<img className='px-4' src="/images/Angle-double-down.svg" alt="" /></h2>
            </div>

        </div >
    )
}

export default Hero