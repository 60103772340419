import React from 'react'

function Footer() {
    return (
        <div className='flex flex-col w-[90%] items-center m-auto border-t border-gray-500'>
            <h1 className='text-center mt-6'>Besoin d'un <span className='font-bold'>site web</span> pour <span className='font-bold'>booster</span> votre <span className='font-bold'>entreprise</span> ?</h1>
            <div className='flex w-[330px] justify-around'>
                <a href="mailto:marco.vassal07@gmail.com" className="w-[150px] my-2 hover:bg-blue-500 bg-white border border-blue-500 text-blue-500 hover:text-white font-bold py-2 px-4 rounded duration-300">Contactez-moi</a>
                <a target='_blank' rel="noreferrer" href="https://marco-vassal.fr/" className="w-[150px] my-2 hover:bg-white text-center bg-blue-500 border border-blue-500 text-white hover:text-blue-500 font-bold py-2 px-4 rounded duration-300">Mon Portfolio</a>
            </div>
            <div className='px-4 pb-6 border-b w-full border-gray-500'>
                <div className='flex items-center my-2 mx-auto lg:w-[40%] sm:w-[70%]'>
                    <img className='w-40 h-48 sm:w-48 sm:h-auto object-cover object-top' src="/images/profile.jpg" alt="" />
                    <p className='text-sm pl-2'>Bonjour, je m'appelle Marco, je suis développeur web front-end et j'ai créé ce site. <br /> <br /> Ensemble, nous pouvons donner vie à un site internet sur mesure, conçu spécialement pour vos besoins !</p>
                </div>
                <ul className='flex flex-wrap justify-center text-xs'>
                    <li className='w-fit bg-blue-500 border border-blue-500 text-white text-sm font-bold py-1 px-2 m-1 rounded'>#E-commerce</li>
                    <li className='w-fit bg-blue-500 border border-blue-500 text-white text-sm font-bold py-1 px-2 m-1 rounded'>#Site Vitrine</li>
                    <li className='w-fit bg-blue-500 border border-blue-500 text-white text-sm font-bold py-1 px-2 m-1 rounded'>#Wordpress</li>
                    <li className='w-fit bg-blue-500 border border-blue-500 text-white text-sm font-bold py-1 px-2 m-1 rounded'>#Shopify</li>
                    <li className='w-fit bg-blue-500 border border-blue-500 text-white text-sm font-bold py-1 px-2 m-1 rounded'>#Portfolio</li>
                </ul>
            </div>
            <div className='text-center my-3'>
                <p className='text-xs my-1'>Stack utilisé: React | Tailwind</p>
                <p className='text-xs my-1'>© Copyright 2023 - fetevotive.fr - Tous droits réservés</p>
                <a href="/legacy" className='text-xs text-d block my-1'>Mentions légales</a>
            </div>
        </div>
    )
}

export default Footer