import React from 'react'

function Verif() {
  return (
    <div>
        <p className=' text-red-600 font-bold text-xs text-center pb-6 mx-4'>Même si j'effectue des contrôles, je ne peux pas garantir l'éxactitude et l'authenticité des informations.</p>
    </div>
  )
}

export default Verif